import request from '@/axios'
import type { UserType } from './types'

interface RoleParams {
  roleName: string
}

export const loginApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/user/login', data })
}

export const loginWithGoogleApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/user/login_with_google', data })
}

export const loginWithDiscordApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/user/login_with_discord', data })
}



export const signupApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/user/signup', data })
}

export const sendVerificationCodeApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/user/send_verification_code', data })
}

export const loginOutApi = (): Promise<IResponse> => {
  return request.get({ url: '/user/loginOut' })
}

export const forgetPasswordApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/user/forget_password', data })
}



export const getUserListApi = ({ params }: AxiosConfig) => {
  return request.get<{
    code: string
    data: {
      list: UserType[]
      total: number
    }
  }>({ url: '/mock/user/list', params })
}

export const getAdminRoleApi = (
  params: RoleParams
): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/role/list', params })
}

export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
  return request.get({ url: '/role/list', params })
}
